import React, { Component } from "react";
import "../App.css";

import { Box, Typography, Container, List, ListItem, ListItemText } from '@mui/material';

import AOS from "aos";
import "aos/dist/aos.css";

import { MOBILE, TABLET, PC } from "./global";

export default class Company extends Component
{
	componentDidMount()
	{
		AOS.init();
	}

	componentWillUnmount()
	{
		clearInterval(this.waveInterval);
	}

	createWave = () =>
	{
		const waveContainer = this.refs.waveContainer;
		const wave = document.createElement('div');
		wave.className = 'wave';
		waveContainer.appendChild(wave);

		wave.addEventListener('animationend', () =>
		{
			wave.remove();
		});
	};

	renderContent = () => (
		<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%" }}>
			<Box sx={{ width: "85%", display: "flex", justifyContent: "flex-start", paddingY: "50px",}}>
				<Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: "#fff", fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem", lg: "3rem" } }}>
					<strong >AI</strong>, <strong>빅데이터</strong>, <strong>생체 신호</strong>,<br />
					그리고 <strong>디지털 혁신</strong>의 중심
				</Typography>
			</Box>
			<Box display="flex" flexDirection={{ xs: "column", md: "row" }} justifyContent="center" alignItems="center">
				<Box component="img" sx={{ width: { xs: "80%", md: "40%" }, height: { xs: "auto", md: "70%" } }} src={process.env.PUBLIC_URL + '/assets/images/cozy.webp'} />
				<Box m={3} justifyContent="center">
					<Container maxWidth="md" sx={{ py: 5, color: "white" }}>
						<Typography variant="h6" sx={{ mb: 1, lineHeight: 1.8, fontSize: { xs: "0.9rem", sm: "1rem", md: "1.2rem", lg: "1.4rem" } }}>
							당사는 <strong style={{ color: "#00a0b9" }}>뇌파(EEG)</strong>, <strong style={{ color: "#00a0b9" }}>심전도(ECG)</strong>, <strong style={{ color: "#00a0b9" }}>근전도(EMG)</strong>, <strong style={{ color: "#00a0b9" }}>안구운동(EOG)</strong> 등 다양한 <strong style={{ color: "#00a0b9" }}>생체 신호 측정</strong> 기술과 고도화된 <strong style={{ color: "#00a0b9" }}>생체정보 분석</strong> 역량을 바탕으로, 혁신적인 <strong style={{ color: "#00a0b9" }}>의료 영상 시스템</strong>과 <strong style={{ color: "#00a0b9" }}>생체계측 장치</strong>를 연구, 개발, 제조 및 판매합니다.
						</Typography>
						<Typography variant="h6" sx={{ mb: 1, lineHeight: 1.5, fontSize: { xs: "0.9rem", sm: "1rem", md: "1.2rem", lg: "1.4rem" } }}>
							또한, <strong style={{ color: "#00a0b9" }}>AI(인공지능)</strong>, <strong style={{ color: "#00a0b9" }}>빅데이터</strong>, <strong style={{ color: "#00a0b9" }}>IoT</strong>, <strong style={{ color: "#00a0b9" }}>클라우드</strong>, <strong style={{ color: "#00a0b9" }}>모바일 기술</strong>을 융합하여 헬스케어 분야를 넘어, <strong style={{ color: "#00a0b9" }}>기업 맞춤형 웹 페이지 개발</strong>과 <strong style={{ color: "#00a0b9" }}>모바일 앱 제작</strong> 분야에서도 선도적인 솔루션을 제공합니다.
						</Typography>
						<Typography variant="h6" sx={{ mb: 1, lineHeight: 1.5, fontSize: { xs: "0.9rem", sm: "1rem", md: "1.2rem", lg: "1.4rem" } }}>
							당사의 두 핵심 사업 부문인 <strong style={{ color: "#00a0b9" }}>의료·생체 신호 기술</strong>과 <strong style={{ color: "#00a0b9" }}>디지털 솔루션 개발</strong>은 각자의 전문성을 바탕으로 상호 시너지를 창출하며 빠르게 성장하고 있습니다.
						</Typography>
						<List sx={{ py: 0 }}>
							{['AI 기반 진단 장치', '의료 빅데이터 분석', '생체 신호 기반 헬스케어 시스템', '웹/앱 플랫폼 개발', '산업 데이터 분석', '스마트 서비스 구축'].map((item, index) => (
								<ListItem key={index} sx={{ py: 0.25 }}>
									<ListItemText primary={<strong style={{ color: "#00a0b9", fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem", lg: "1.2rem" } }}>{item}</strong>} />
								</ListItem>
							))}
						</List>
					</Container>
				</Box>
			</Box>
		</Box>
	);

	render()
	{
		return (
			<>
				<PC>
					<Box position="relative" sx={{ width: "100%", height: "100vh", backgroundColor: "black", display: "flex", justifyContent: "center", alignItems: "center" }}>
						{this.renderContent(false)}
					</Box>
				</PC>
				<TABLET>
					<Box sx={{ width: "100vw", minHeight: "100vh", backgroundColor: "black", display: "flex", justifyContent: "center", alignItems: "center", paddingY: "100px" }}>
						{this.renderContent(false)}
					</Box>
				</TABLET>
				<MOBILE>
					<Box sx={{ width: "100vw", minHeight: "100vh", backgroundColor: "black", display: "flex", justifyContent: "center", alignItems: "center" }}>
						{this.renderContent(true)}
					</Box>
				</MOBILE>
			</>
		);
	}
};

import React, { Component } from "react";
import "../App.css";

import { Box, Tabs, Tab, Pagination } from "@mui/material";

import AOS from "aos";
import "aos/dist/aos.css";

import { MOBILE, TABLET, PC } from "./global";

export default class Partners extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			selectedTab: 0, // To track the currently selected tab
			currentPage: 1, // To track the current page for case 1
		};
	}

	componentDidMount()
	{
		AOS.init();
	}

	handleTabChange = (event, newValue) =>
	{
		this.setState({ selectedTab: newValue, currentPage: 1 }); // Reset to page 1 when tab changes
	};

	handlePageChange = (event, value) =>
	{
		this.setState({ currentPage: value });
	};

	renderContent(device)
	{
		const { selectedTab, currentPage } = this.state;

		const itemsPerPage = 12;
		let boxStyle;
		let containerStyle = { display: "flex", flexWrap: "wrap", gap: 2, justifyContent: "center", alignItems: "center", textAlign: "center", padding: 2 };

		if (device === "MOBILE")
		{
			boxStyle = { width: 100, height: 60 };
			containerStyle = { ...containerStyle, justifyContent: "space-evenly", width: "100%" };
		}
		else if (device === "TABLET")
		{
			boxStyle = { width: 150, height: 80 };
			containerStyle = { ...containerStyle, justifyContent: "space-evenly", width: "100%" };
		}
		else
		{
			boxStyle = { width: 200, height: 80 };
		}

		switch (selectedTab)
		{
			case 0:
				return (
					<Box sx={containerStyle}>
						{[
							{ image: "i2a.jpg", url: "https://i2asys.com/" },
							{ image: "aidanbio.png", url: "http://aidanbio.com/" },
							{ image: "초지능.png", url: "https://kaisttouch.modoo.at/" },
							{ image: "perzeam.png", url: "https://perzeam.com/" },
							{ image: "innogrid.png", url: "https://www.innogrid.com/" },
							{ image: "cbh.webp", url: "http://www.c-bh.co.kr/" },
							{ image: "sijung.webp", url: "http://www.sijung.com/" },
							{ image: "peopleinnet.png", url: "https://peopleinnet.com/" },
							{ image: "gns.jpg", url: "https://gnsinter.com/default/" },
							{ image: "solmitech.png", url: "https://solmitech.com/" },
							{ image: "bmtech.png", url: "http://www.bmtek.kr/" },
							{ image: "마젠타로보틱스.png", url: "https://www.magentarobotics.com/" },
							{ image: "bnblabs.png", url: "http://bnblabs.co.kr/" },
							{ image: "dahantech.png", url: "http://www.dahan.co.kr/" },
							{ image: "crengine.png", url: "https://www.crengine.net/" },
							{ image: "simtech.gif", url: "http://www.simtech.co.kr/" },
							{ image: "intocns.png", url: "https://www.intocns.com/" },
							{ image: "mib.png", url: "https://www.hyundaimib.com/" },
							{ image: "lst.png", url: "http://www.lstgrp.com//" },
							{ image: "artise.png", url: "https://www.artisebio.com/" },
							{ image: "wegstr.png", url: "https://wegstr.com/" },
						].map((item, index) => (
							<Box
								key={index}
								component="img"
								src={process.env.PUBLIC_URL + `/assets/images/${item.image}`}
								alt={item.image.split(".")[0]}
								onClick={() => window.open(item.url, "_blank")} // 새 탭으로 열기
								sx={{
									...boxStyle, // 반응형 스타일 적용
									padding: 2,
									objectFit: "scale-down",
									objectPosition: "center",
									backgroundColor: "white",
									borderRadius: "8px",
									boxShadow: 2,
									cursor: "pointer", // 클릭 가능한 포인터 표시
									transition: "transform 0.2s",
									"&:hover": {
										transform: "scale(1.05)", // 호버 시 살짝 확대
									},
								}}
							/>
						))}
					</Box>
				);
			case 1:
				const categories = {
					"기업": [
						{ image: "vgen.png", url: "https://vgen.co.kr/" },
						{ image: "POSCO.png", url: "https://www.posco.co.kr/" },
						{ image: "u2system.png", url: "http://u2system.co.kr/" },
						{ image: "solum.jpg", url: "https://www.solum-group.co.kr/" },
						{ image: "ssecretw.png", url: "http://ssecretwoman.com/" },
						{ image: "baroyeon.png", url: "https://www.baroyeon.co.kr/" },
						{ image: "megaworks.png", url: "http://megaworks.ai/" },
						{ image: "rndb.png", url: "http://rndb.co.kr/" },
						{ image: "SOLETOP.png", url: "https://soletop.co.kr/" },
					],
					"대학교": [
						{ image: "건양대학교.png", url: "https://www.konyang.ac.kr/" },
						{ image: "공주대학교.jpg", url: "https://www.kongju.ac.kr/" },
						{ image: "전남대학교.png", url: "https://www.jnu.ac.kr/" },
						{ image: "단국대학교.png", url: "https://www.dankook.ac.kr/web/kor" },
						{ image: "순천향대학교.png", url: "https://www.sch.ac.kr/" },
						{ image: "숭실대학교.jpg", url: "https://www.ssu.ac.kr/" },
						{ image: "충남대학교.png", url: "https://www.cnu.ac.kr/" },
						{ image: "국민대학교.jpg", url: "https://www.kookmin.ac.kr/" },
						{ image: "동의대학교.png", url: "https://www.deu.ac.kr/" },
						{ image: "대구대학교.jpg", url: "https://www.daegu.ac.kr/" },
						{ image: "호남대학교.jpg", url: "https://www.honam.ac.kr/" },
						{ image: "연세대학교.png", url: "https://www.yonsei.ac.kr/" },
						{ image: "가천대학교.jpg", url: "https://www.gachon.ac.kr/" },
						{ image: "경상대학교.jpg", url: "https://www.gnu.ac.kr/" },
						{ image: "한양대학교.png", url: "https://www.hanyang.ac.kr/" },
						{ image: "금오공과대학.png", url: "https://www.kumoh.ac.kr/" },
						{ image: "대전대학교.jpg", url: "https://www.dju.ac.kr/" },
						{ image: "고려대학교.png", url: "https://www.korea.ac.kr/" },
						{ image: "삼육대학교.png", url: "https://www.syu.ac.kr/" },
						{ image: "대구카톨릭.png", url: "https://www.cu.ac.kr/" },
						{ image: "동신대학교.png", url: "https://www.dsu.ac.kr/" },
						{ image: "한림대학교.png", url: "https://www.hallym.ac.kr/" },
						{ image: "동아대학교.jpg", url: "https://www.donga.ac.kr/" },
						{ image: "한경국대.png", url: "https://www.hknu.ac.kr/" },
						{ image: "강원대학교.png", url: "https://wwwk.kangwon.ac.kr/" },
						{ image: "아주대학교.png", url: "https://www.ajou.ac.kr/" },
						{ image: "진주교대.jpg", url: "https://www.cue.ac.kr/" },
						{ image: "울산대학교.png", url: "https://www.ulsan.ac.kr/" },
						{ image: "을지대학교.jpg", url: "https://www.eulji.ac.kr/" },
						{ image: "카이스트.png", url: "https://www.kaist.ac.kr/" },
						{ image: "GIST.jpg", url: "https://www.gist.ac.kr/" },
						{ image: "UNIST.png", url: "https://www.unist.ac.kr/" },
					],
					"의원": [
						{ text: "연세맘스닥터", url: "https://blog.naver.com/momsdoctor1004/223466971219/" },
						{ text: "마음토닥의원", url: "https://mindhealthcenter.co.kr/" },
						{ text: "소망정신과의원(대전)", url: "#" },
						{ text: "연세신통의원(수원)", url: "#" },
						{ text: "청음이비인후과(진해)", url: "#" },
					],
					"기관": [
						{ image: "K-MEDI hub.png", url: "https://www.kmedihub.re.kr/" },
						{ image: "국립원예특작과학원.png", url: "https://www.nihhs.go.kr/" },
						{ image: "KIMM_logo.png", url: "https://www.kimm.re.kr/" },
						{ image: "KETI.jpg", url: "https://www.keti.re.kr/" },
						{ image: "ETRI.png", url: "https://www.etri.re.kr/" },
						{ image: "한국자동차연구원.gif", url: "https://www.katech.re.kr/" },
						{ image: "한의학연구원.jpg", url: "https://kiom.re.kr/" },
						{ image: "원자력연구원.jpg", url: "https://www.kaeri.re.kr/" },
						{ image: "한글박물관.png", url: "https://www.hangeul.go.kr/" },
						{ image: "공군군수사령부.jpg", url: "https://airforce.mil.kr/user/indexMain.action?siteId=lcint" },
					],
				};

				let currentCategory;
				if (currentPage === 1)
				{
					currentCategory = categories["기업"];
				}
				else if (currentPage >= 2 && currentPage <= 4)
				{
					currentCategory = categories["대학교"].slice((currentPage - 2) * itemsPerPage, (currentPage - 1) * itemsPerPage);
				}
				else if (currentPage === 5)
				{
					currentCategory = categories["의원"];
				}
				else if (currentPage === 6)
				{
					currentCategory = categories["기관"];
				}
				else
				{
					currentCategory = [];
				}

				return (
					<>
						<Box>
							<Box
								sx={{
									textAlign: "center",
									fontSize: "1.8rem",
									color: "white",
									fontWeight: "bold",
									marginY: 5,
								}}
							>
								{currentPage === 1
									? "기업"
									: currentPage >= 2 && currentPage <= 4
										? "대학교"
										: currentPage === 5
											? "의원"
											: currentPage === 6
												? "기관"
												: ""}
							</Box>
							<Box sx={{ ...containerStyle, justifyContent: "center", alignItems: "center", marginBottom: "50px" }}>
								{currentCategory.map((item, index) =>
									item.image ? (
										<Box
											key={index}
											component="img"
											src={process.env.PUBLIC_URL + `/assets/images/${item.image}`}
											alt={item.image.split(".")[0]}
											onClick={() => window.open(item.url, "_blank")}
											sx={{
												...boxStyle,
												padding: 2,
												objectFit: "scale-down",
												objectPosition: "center",
												backgroundColor: item.image === "ssecretw.png" ? "black" : "white",
												borderRadius: "8px",
												boxShadow: 2,
												cursor: "pointer",
												transition: "transform 0.2s",
												border: item.image === "ssecretw.png" ? "2px solid white" : "none",
												"&:hover": {
													transform: "scale(1.05)",
												},
											}}
										/>
									) : (
										<Box
											key={index}
											onClick={() => window.open(item.url, "_blank")}
											sx={{
												...boxStyle,
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												padding: 2,
												backgroundColor: "white",
												borderRadius: "8px",
												boxShadow: 2,
												cursor: "pointer",
												transition: "transform 0.2s",
												"&:hover": {
													transform: "scale(1.05)",
												},
												fontSize: device === "MOBILE" ? "1rem" : "1.3rem", // 모바일일 때 폰트 크기 조정
												fontWeight: "bold",
												textAlign: "center",
											}}
										>
											{item.text}
										</Box>
									)
								)}
							</Box>
							<Pagination
								count={6}
								page={currentPage}
								onChange={this.handlePageChange}
								color="primary"
								sx={{
									width: "100%",
									position: "absolute", // 화면에 고정
									bottom: 1, // 화면 하단에서 16px 위로
									left: "50%", // 화면 중앙에 위치
									transform: "translateX(-50%)", // 가운데 정렬 보정
									display: "flex",
									justifyContent: "center",
									"& .MuiPaginationItem-root": {
										color: "white",
										backgroundColor: "transparent",
										fontSize: device === "MOBILE" ? "0.75rem" : "1rem", // 모바일에서 크기 줄이기
										padding: device === "MOBILE" ? "0px" : "8px", // 모바일에서 패딩 줄이기
									},
									"& .MuiPaginationItem-root.Mui-selected": {
										color: "black",
										backgroundColor: "white",
									},
									"@media (max-width: 600px)": {
										"& li": {
											// padding: 0,
											margin: 0,
										},
									},
								}}
							/>
						</Box>
					</>
				);
			default:
				return (
					<Box sx={{ textAlign: "center", color: "white", padding: 4 }}>
						<p>잘못된 탭입니다. 다시 시도해주세요.</p>
					</Box>
				);
		}
	}

	render()
	{
		const { selectedTab } = this.state;

		return (
			<>
				<PC>
					<Box
						position="relative"
						sx={{
							width: "95%",
							height: "80vh",
							paddingTop: "250px",
							backgroundColor: "black",
							display: "flex",
							flexDirection: "column", // 세로 정렬
							justifyContent: "center",
						}}
					>
						<Box
							sx={{
								width: "100%",
								backgroundColor: "black",
								textAlign: "center",
								paddingBottom: "100px",
								position: "sticky", // 고정 위치
								top: 0, // 최상단 고정
								zIndex: 1000, // 다른 요소 위로
							}}
						>
							<Tabs
								value={selectedTab}
								onChange={this.handleTabChange}
								indicatorColor="secondary"
								textColor="inherit"
								centered
								sx={{
									".MuiTabs-indicator": {
										backgroundColor: "white",
									},
									".MuiTab-root": {
										color: "white",
										fontSize: "2rem",
									},
								}}
							>
								<Tab label="협력사" />
								<Tab label="고객사" />
							</Tabs>
						</Box>
						{/* 스크롤 가능한 콘텐츠 영역 */}
						<Box
							sx={{
								position: "relative",
								flex: 1, // 나머지 공간 채우기
								overflowY: "auto", // 세로 스크롤 허용
								padding: 2,

							}}
						>
							{this.renderContent("PC")}
						</Box>
					</Box>
				</PC>
				<TABLET>
					<Box
						position="relative"
						sx={{
							width: "100%",
							height: "100%",
							backgroundColor: "black",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Box
							sx={{
								width: "80%",
								backgroundColor: "black",
								borderRadius: "8px",
								boxShadow: 3,
								textAlign: "center",
							}}
						>
							<Tabs
								value={selectedTab}
								onChange={this.handleTabChange}
								indicatorColor="secondary"
								textColor="inherit"
								centered
								sx={{
									".MuiTabs-indicator": {
										backgroundColor: "white",
									},
									".MuiTab-root": {
										color: "white",
										fontSize: "1.5rem",
									},
								}}
							>
								<Tab label="협력사" />
								<Tab label="고객사" />
							</Tabs>
							<Box sx={{ padding: 2 }}>{this.renderContent("TABLET")}</Box>
						</Box>
					</Box >
				</TABLET >
				<MOBILE>
					<Box
						position="relative"
						sx={{
							width: "100%",
							height: "90%",
							paddingY: 20,
							backgroundColor: "black",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Box
							sx={{
								width: "100%",
								backgroundColor: "black",
								borderRadius: "8px",
								boxShadow: 3,
								textAlign: "center",
							}}
						>
							<Tabs
								value={selectedTab}
								onChange={this.handleTabChange}
								indicatorColor="secondary"
								textColor="inherit"
								centered
								sx={{
									".MuiTabs-indicator": {
										backgroundColor: "white",
									},
									".MuiTab-root": {
										color: "white",
										fontSize: "1.5rem",
									},
								}}
							>
								<Tab label="협력사" />
								<Tab label="고객사" />
							</Tabs>
							<Box sx={{ padding: 2 }}>{this.renderContent("MOBILE")}</Box>
						</Box>
					</Box>
				</MOBILE>
			</>
		);
	}
}
